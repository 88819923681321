import React from "react";
import PropTypes from 'prop-types';
import './Link.css';

const Link = ({ url, children }) => {
    const formattedUrl = /^(https?:\/\/)/.test(url) ? url : `http://${url}`;

    return <a href={formattedUrl} className="link" target='_blank' rel="noopener noreferrer">
        {children}
    </a>
};

Link.propTypes = {
    url: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default Link;

