import React from "react";
import PropTypes from 'prop-types';
import Link from "../../atoms/Link";
import Icon from "../../atoms/Icon";
import './SocialLink.css';

const SocialLink = ({ icon, url }) => (
    <Link url={url}>
        <Icon icon={icon} />
    </Link>
);

SocialLink.propTypes = {
    icon: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired
};

export default SocialLink;