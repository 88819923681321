import React from "react";
import PropTypes from 'prop-types';
import UserInfo from "../../molecules/UserInfo";
import ContactItem from "../../molecules/ContactItem";
import ProfileButton from "../../molecules/ProfileButton";
import './ProfileCard.css';

const ProfileCard = ({ icon1, icon2, icon3, icon4, icon5, url }) => (
    <div class='profile-card'>
        <UserInfo name="Stefan Griffiths" role="Software Developer" />
        <div className="profile-card-contact-item-container">
            <ContactItem type='span' text="Stefan Griffiths" icon={icon3} linkIcon={icon5} url={url} />
            <ContactItem type='span' text="stefan.griffiths@outlook.com" icon={icon4} />
        </div>
        <div className="profile-card-button-container">
            <ProfileButton label='Download' type='primary' icon={icon1} />
            <ProfileButton label='Share' type='secondary' icon={icon2} />
        </div>
    </div>
);

ProfileCard.propTypes = {
    icon1: PropTypes.node.isRequired,
    icon2: PropTypes.node.isRequired,
    icon3: PropTypes.node.isRequired,
    icon4: PropTypes.node.isRequired,
    icon5: PropTypes.node.isRequired
};

export default ProfileCard;