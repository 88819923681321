import React from "react";
import PropTypes from 'prop-types';
import Button from "../../atoms/Button";
import Icon from '../../atoms/Icon';
import './ProfileButton.css';

const ProfileButton = ({ icon, label, type, onClick }) => (
    <Button onClick={onClick} className={`btn-profile ${type}`}>
        { label }
        <Icon icon={icon} className={`icon`} />
    </Button>
);

ProfileButton.propTypes = {
    icon: PropTypes.object,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    onClick: PropTypes.func
}

ProfileButton.defaultProps = {
    icon: null,
    type: 'primary',
    onClick: () => {}
}

export default ProfileButton;