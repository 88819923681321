import React from "react";
import PropTypes from 'prop-types';
import './Button.css';

const Button = ({ children, onClick, className }) => (
    <button className={`button ${className}`} onClick={onClick} >
        { children }
    </button>
);

Button.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string
};

Button.defaultProps = {
    onClick: () => {},
    class: ''
};

export default Button;