import React from "react";
import PropTypes from 'prop-types';
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import SocialLink from "../SocialLink";
import './ContactItem.css';

const ContactItem = ({ icon, type, text, url, linkIcon}) => (
    <div className="contact-item">
        <Icon icon={icon} className="contact-icon" />
        <Text type={type} className="contact-text">{text}</Text>
        {url && <SocialLink url={url} icon={linkIcon} className="contact-link"/>}
    </div>
);

ContactItem.propTypes = {
    icon: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
    linkIcon: PropTypes.node
};

export default ContactItem;

