import React from 'react';
import PropTypes from 'prop-types';
import ProfileImage from '../../atoms/ProfileImage';
import Text from '../../atoms/Text';
import './UserInfo.css';

const UserInfo = ({ name, role }) => (
    <div className='user-info'>
        <ProfileImage />
        <div className='user-info-text'>
            <Text type="h1">{name}</Text>
            <Text type="p">{role}</Text>
        </div>
    </div>
);

UserInfo.propTypes = {
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
};

export default UserInfo;