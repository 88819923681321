import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ProfileCard from './components/organisms/ProfileCard';
import { faDownload, faShareNodes, faArrowUpRightFromSquare, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div className="App">
      <ProfileCard icon1={faDownload} icon2={faShareNodes} icon3={faLinkedin} icon4={faEnvelope} icon5={faArrowUpRightFromSquare} url="https://www.linkedin.com/in/stefan-griffiths/"/>
    </div>
  );
}

export default App;
