import React from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = ({ icon, className }) => (
    <FontAwesomeIcon icon={icon} className={className} />
);

Icon.propTypes = {
    icon: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default Icon;