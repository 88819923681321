import React from 'react';
import PropTypes from 'prop-types';
import './Text.css';

const Text = ({ type, className, children}) => {
    const Component = type;
    return <Component className={className}>{children}</Component>
};

Text.propTypes = {
    type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div']),
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

Text.defaultProps = {
    type: 'p'
};

export default Text;