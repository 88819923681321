import React from "react";
import PropTypes from 'prop-types';
import './ProfileImage.css';
import defaultProfilePicture from '../../../assets/images/ProfileImage.jpeg';

const ProfileImage = ({ src }) => (
    <div class="profile-picture">
        <img src={src || defaultProfilePicture} alt=""/>
    </div>
);

ProfileImage.propTypes = {
    src: PropTypes.string
};

ProfileImage.defaultProps = {

};

export default ProfileImage;